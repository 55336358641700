import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import img1 from "./assets/images/6 Reason image 1.png";
import img2 from "./assets/images/6 Reason image 2.png";
import img3 from "./assets/images/6 Reason image 3.png";
import img4 from "./assets/images/6 Reason image 4.png";
import img5 from "./assets/images/6 Reason image 5.png";
import img6 from "./assets/images/6 Reason image 6.png";
import Banner1 from "./assets/images/Banner 1.jpeg";
import Banner1Mobile from "./assets/images/mobile_responsive/Banner 1.png";

const AboutUs = () => {
  const [info, setInfo] = useState(false);

  return (
    <div className="mt-4 unselectable">
      <div className="text-center">
        <div className="col-md-8 " style={{ margin: "auto" }}>
          <h1 className="headings mb-3">Want To Know Us More ?</h1>
          <p
            style={{
              textAlign: "justify",
              textAlignLast: "center",
              lineHeight: "1.6",
            }}
          >
            Nimap Infotech is a leading Software Development and Outsourcing
            company based in Mumbai in India and Dubai & USA Internationally. We
            provide on-demand software development teams on digital transforming
            technology and that too within 1 hour.
          </p>
        </div>
        {info ? (
          <div className="col-md-8" style={{ margin: "auto" }}>
            <p
              style={{
                textAlign: "justify",
                textAlignLast: "center",
                lineHeight: "1.6",
              }}
            >
              We at Nimap Infotech, offer an opportunity to hire developers in
              India with an army of great development expertise. Whether your
              requirements are for long-term or short-term or hourly-basis, you
              get it all.
            </p>
            <p
              style={{
                textAlign: "justify",
                textAlignLast: "center",
                lineHeight: "1.6",
              }}
            >
              Nimap Infotech delivers solutions that are nothing short of
              perfection from MEAN, MERN, Front-end, back-end and Full-stack
              Development to agile websites or catchy apps, we have it all under
              one roof. Hire 1 to 35 developers through RaaS, our highly
              appreciated and implemented System through Resource as a Service
              system for your project. Whether you need to create a bespoke
              application or update and maintain an existing codebase, Nimap
              Infotech has you covered.
            </p>
            <button className="button" onClick={() => setInfo(false)}>
              SHOW LESS
            </button>
          </div>
        ) : (
          <button className="button" onClick={() => setInfo(true)}>
            READ MORE
          </button>
        )}
      </div>
      <div>
        <div
          className="col-md-9 mt-4"
          style={{
            margin: "auto",
          }}
        >
          <div className="row">
            <div className="col-md-4 mt-4">
              <div className="box1">
                <div className="box-content">
                  <h1>
                    <CountUp end={12} duration={3}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </h1>
                  <h4> Years in IT Outsourcing</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-4">
              <div className="box1">
                <div className="box-content">
                  <h1>
                    <CountUp end={400} duration={3}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </h1>
                  <h4> Developers &nbsp; Strong </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-4">
              <div className="box1">
                <div className="box-content">
                  <h1>
                    <CountUp end={1200} duration={3}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </h1>
                  <h4>Projects Completed</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-4 mt-4">
              <div className="box1">
                <div className="box-content">
                  <h1>
                    <CountUp end={500} duration={3}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </h1>
                  <h4>Satisfied Customers</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-4">
              <div className="box1">
                <div className="box-content">
                  <h1>
                    <CountUp end={40} duration={3}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    &nbsp;Hour
                  </h1>
                  <h4>Risk-Free Onboarding</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-4">
              <div className="box1">
                <div className="box-content">
                  <h1>
                    <CountUp end={1} duration={3}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    &nbsp;Hour
                  </h1>
                  <h4>Hire On-demand Software Expert</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 mt-5">
        <div className="banner-container">
          <img
            className="banner-img"
            alt=""
            src={Banner1}
            style={{ width: "100%", height: "100%", pointerEvents: "none" }}
          ></img>
          <button className="btn">
            <a href="https://calendly.com/nagdasagar/" style={{ textDecoration: "none" }}>
              GET 30-MIN FREE CONSULTATION
            </a>
          </button>
          {/* for mobile Responsive */}
          <a href="https://calendly.com/nagdasagar/" style={{ textDecoration: "none" }}>
            <img
              className="mobile-banner"
              alt=""
              src={Banner1Mobile}
              style={{ width: "110%", height: "110%" }}
            />
          </a>
        </div>
      </div>
      <div>
        <div className="col-md-7 mt-5">
          <h1 className="headings">6 Reasons Why Companies Trust Us</h1>
          <h4 className="sub-headings mt-3">
            Get More Done by having 1 - 35 Team of Skilled developers on your
            side
          </h4>
        </div>
        <div className="row mt-5">
          <div className="col-md-6 reasons-card">
            <div className="row">
              <div className="col-md-4">
                <img
                  alt=""
                  src={img1}
                  style={{ pointerEvents: "none" }}
                  className="about-us-img"
                ></img>
              </div>
              <div className="col-md-8">
                <h4>Focus on your core business</h4>
                <p>
                  Spend more time on taking your business ideas to the next
                  level exceeding the quality benchmark and our team will
                  support you as a dedicated partner.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6  reasons-card">
            <div className="row">
              <div className="col-md-4">
                <img
                  alt=""
                  src={img2}
                  style={{ pointerEvents: "none" }}
                  className="about-us-img"
                ></img>
              </div>
              <div className="col-md-8">
                <h4>Quick Team Scaling</h4>
                <p>
                  You can hire a developer and extend the size of the project
                  team as per your business requirements.This not only boosts
                  productivity but also helps in the smooth execution of the
                  whole project.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6  reasons-card">
            <div className="row">
              <div className="col-md-4">
                <img
                  alt=""
                  src={img3}
                  style={{ pointerEvents: "none" }}
                  className="about-us-img"
                ></img>
              </div>
              <div className="col-md-8">
                <h4>No more recruitment worries</h4>
                <p>
                  We get you the talent fast with no payroll and no overhead
                  needed to be associated with infrastructure staffing and
                  operations.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6  reasons-card">
            <div className="row">
              <div className="col-md-4">
                <img
                  alt=""
                  src={img4}
                  style={{ pointerEvents: "none" }}
                  className="about-us-img"
                ></img>
              </div>
              <div className="col-md-8">
                <h4>We keep nothing hidden</h4>
                <p>
                  We strive to enforce long-term prosperity rather than
                  short-term success with transparent communication and the
                  signing of confidential NDAs while we partner.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6  reasons-card">
            <div className="row">
              <div className="col-md-4">
                <img
                  alt=""
                  src={img5}
                  style={{ pointerEvents: "none" }}
                  className="about-us-img"
                ></img>
              </div>
              <div className="col-md-8">
                <h4>Our experts keep up to the promise</h4>
                <p>
                  Meet business peaks with timely delivery of tasks by our
                  expert programmers and stay informed about the progress to
                  help in driving your business forward.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6  reasons-card">
            <div className="row">
              <div className="col-md-4">
                <img
                  alt=""
                  src={img6}
                  style={{ pointerEvents: "none" }}
                  className="about-us-img"
                ></img>
              </div>
              <div className="col-md-8">
                <h4>100% Client Satisfaction</h4>
                <p>
                  Our team of expert engineers and developers ensures that
                  best-in-class services are rendered to your business
                  requirements.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            className="button mt-5"
            onClick={() =>
              window.scroll({
                top: document.body.offsetHeight,
                left: 0,
                behavior: "smooth",
              })
            }
          >
            GET IN TOUCH
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
