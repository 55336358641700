import React, { useRef } from "react";
import AboutUs from "./aboutUs";
import Footer from "./footer";
import HiringModel from "./hiringModel";
import HiringProcess from "./hiringProcess";
import Introduction from "./introduction";
import Navbar from "./navbar";
import Slider from "./sliderComponent/slider";
import Technologies from "./technologies";
import Testimonials from "./testimonials";
import PopApp from "./PopApp";
import TopRightVector from "./assets/images/top_image.jpg";

const HomePage = () => {
  const home = useRef(null);
  const hiring = useRef(null);
  const technology = useRef(null);
  const aboutUs = useRef(null);
  const testimonials = useRef(null);
  const contactUs = useRef(null);

  const handleScroll = (id) => {
    switch (id) {
      case "hiring":
        return scrollToRef(hiring);
      case "tech":
        return scrollToRef(technology);
      case "aboutus":
        return scrollToRef(aboutUs);
      case "testimonials":
        return scrollToRef(testimonials);
      case "contactus":
        return scrollToRef(contactUs);
      default:
        return scrollToRef(home);
    }
  };

  const scrollToRef = (ref) => {
    window.scrollTo(0, ref.current.offsetTop);
  };

  return (
    <div>
      <div
        style={{
          marginLeft: "40px",
        }}
      >
        <Navbar scrollitem={handleScroll}></Navbar>
      </div>

      <div
        className="mobile-view"
        style={{
          color: "white",
        }}
      >
        <div className="col-md-3 p-5">
          <Introduction></Introduction>
        </div>
      </div>

      <div className="p-5 ">
        <div className="intro mb-5">
          <div className="col-md-7">
            <Introduction></Introduction>
          </div>
          <div className="col-md-5 p-0 intro-img">
            <img
              alt=""
              src={TopRightVector}
              style={{
                marginLeft: "86px",
                width: "85%",
                height: "85%",
              }}
            />
          </div>
        </div>
        <div className="">
          <Slider></Slider>
        </div>
        <div className="mt-4" ref={hiring}>
          <HiringProcess></HiringProcess>
        </div>
        <div className="mt-4" ref={technology}>
          <Technologies></Technologies>
        </div>
        <div className="mt-5" ref={aboutUs}>
          <PopApp></PopApp>
        </div>
        <div className="mt-5" ref={aboutUs}>
          <AboutUs></AboutUs>
        </div>
        <div className="mt-3">
          <HiringModel></HiringModel>
        </div>
        <div className="mt-4" ref={testimonials}>
          <Testimonials></Testimonials>
        </div>
      </div>
      <div ref={contactUs}>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default HomePage;
