import React from "react";
const Introduction = () => {
  return (
    <div className="row unselectable">
      <div className="col">
        <h1 className="headings ">
          Do you Feel a Resource Crunch in Your Company ?
        </h1>
        <h4 className="sub-headings mt-3 mb-3 ">
          Hire 'RaaS' On-Demand IT Resources To Reach New Business Heights
        </h4>
        <p className="para ">
          Get the perfect balance between cost, time and quality developer.
          Begin with hiring resources with our RaaS model from our 400+
          specialists to be on your beck.
        </p>
        <hr />
        <p className="para">
          When you choose us, you get trust in a brand, India's top Dedicated
          Development team, 12+ years of experience, dedicated developer teams
          within 1 hour and scale-up with complete peace of mind.
        </p>
        <button className=" button first_button">
          Call :&nbsp;
          <a
            href="tel:+919324497696"
            style={{ color: "white", textDecoration: "none" }}
          >
            +91 9324497696
          </a>
        </button>
      </div>
    </div>
  );
};

export default Introduction;
