import React from "react";
import logo1 from "./../assets/images/Company Logo/Logo 1.png";
import logo2 from "./../assets/images/Company Logo/Logo 2.png";
import logo3 from "./../assets/images/Company Logo/Logo 3.png";
import logo4 from "./../assets/images/Company Logo/Logo 4.png";
import logo5 from "./../assets/images/Company Logo/Logo 5.png";
import logo6 from "./../assets/images/Company Logo/Logo 6.png";
import logo7 from "./../assets/images/Company Logo/Logo 7.png";
import logo8 from "./../assets/images/Company Logo/Logo 8.png";
import logo9 from "./../assets/images/Company Logo/Logo 9.png";
//import logo10 from "./../assets/images/Company Logo/Logo 10.png";
import logo11 from "./../assets/images/Company Logo/Logo11.png";
import logo12 from "./../assets/images/Company Logo/Logo12.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./style.css";
import { BsCircleFill } from "react-icons/bs";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    paritialVisibilityGutter: 100,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

const images = [
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo11,
  logo12,
];

const Slider = () => {
  const CustomDot = ({ onMove, index, onClick, active }) => {
    return (
      <li style={{ fontSize: "12px" }} onClick={() => onClick()}>
        <BsCircleFill color={active ? "#0765f6" : "lightgray"} />
        &nbsp; &nbsp;
      </li>
    );
  };

  return (
    <div
      className="col-md-11 mb-3"
      style={{
        margin: "auto",
        paddingBottom: "30px",
        position: "relative",
      }}
    >
      <Carousel
        itemClass="image-item"
        responsive={responsive}
        arrows={false}
        showDots={true}
        customDot={<CustomDot />}
        autoPlay
        autoPlaySpeed={3000}
        infinite
        renderDotsOutside={true}
      >
        {images.map((image, i) => {
          return (
            <img
              key={i}
              alt=""
              className="slider-img"
              draggable={false}
              style={{ width: "100%", height: "100%", pointerEvents: "none" }}
              src={image}
            />
          );
        })}
      </Carousel>
    </div>
  );
};

export default Slider;
