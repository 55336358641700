import React from "react";
import ContactForm from "./contactForm";
import SixSteps from "./assets/images/6 Easy Steps.jpeg";
import SixStepsMobile from "./assets/images/mobile_responsive/Six Steps.jpeg";

const HiringProcess = () => {
  return (
    <div className="unselectable">
      <div className="row">
        <div className="col-md-7">
          <h1 className="headings">
            Why Hire Dedicated Developers For Your Projects ?
          </h1>
          <br />
          <div className="row">
            <div className="col-md-4">
              <h2 style={{ fontWeight: "bold", color: "#0765f6" }}>40%</h2>
              <h4 className="sub-headings">Less Expensive</h4>
            </div>
            <div className="col">
              <h2 style={{ fontWeight: "bold", color: "#0765f6" }}>0%</h2>
              <h4 className="sub-headings"> Developer Backout</h4>
            </div>
          </div>
          <br />
          <p className="para">
            A dedicated developer can help your business to embark on a digital
            tranformation journey within a faster turnaround time and lesser
            cost. Here are the reasons to follow this approach.
          </p>
          <br />
          <h4 className="sub-headings"> START 10 DAYS RISK-FREE TRIAL </h4>
          <br />
          <p>Hire on-demand dedicated Developer within 60 Minutes</p>
          <br />
          <div className="row" style={{ textAlign: "center" }}>
            <div className="col-md-5 hiring-process-cards">
              Save Upto 40% of your Costs
            </div>
            <div className="col-md-5 hiring-process-cards">
              {" "}
              Flexible Engagement Models
            </div>
            <div className="col-md-5 hiring-process-cards">
              Same Business Hours
            </div>
            <div className="col-md-5 hiring-process-cards">
              {" "}
              Easy Team Scale-up
            </div>
            <div className="col-md-5 hiring-process-cards">
              Zero Developer Backout
            </div>
            <div className="col-md-5 hiring-process-cards">
              {" "}
              Experienced Programmers
            </div>
            <div className="col-md-5 hiring-process-cards">
              Excellent Communication
            </div>
            <div className="col-md-5 hiring-process-cards">
              {" "}
              Strict NDA Terms
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <ContactForm></ContactForm>
        </div>
        <div className="text-center">
          <div className="col-md-8" style={{ margin: "auto" }}>
            <h1 className="headings col-md-10 mb-3" style={{ margin: "auto" }}>
              6 Easy Steps to Make Resources All Yours in 1 Hr
            </h1>
            <p
              className="para"
              style={{
                textAlignLast: "center",
              }}
            >
              Hire Dedicated Developers for your Startup or Project simply and
              seamlessly to meet your business needs. Get started by screening
              from our readily available on-bench resources to hire now.
            </p>
          </div>
        </div>
      </div>

      <div className="col-md-12 text-center mt-3">
        <div className="desktop-view">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-2 steps-img-heads">
              Consult with our experts
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-2 steps-img-heads">
              Shortlist from the profiles
            </div>

            <div className="col-md-2"></div>
            <div className="col-md-2 steps-img-heads">
              Onboarding the resource
            </div>
          </div>

          <img
            src={SixSteps}
            style={{ height: "100%", width: "100%", pointerEvents: "none" }}
            alt=""
          />

          <div className="row">
            <div className="col-md-2 steps-img-heads">Drop an inquiry</div>
            <div className="col-md-2"></div>
            <div className="col-md-2 steps-img-heads">
              Screen our candidates
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-2 steps-img-heads">Evaluate candidates</div>

            <div className="col-md-2"></div>
          </div>
        </div>

        <div className="mobile-view">
          <img
            src={SixStepsMobile}
            style={{ height: "100%", width: "100%", pointerEvents: "none" }}
            alt=""
          />
        </div>

        <button
          className="button mt-5"
          onClick={() =>
            window.scroll({
              top: document.body.offsetHeight,
              left: 0,
              behavior: "smooth",
            })
          }
        >
          GET STARTED NOW
        </button>
      </div>
    </div>
  );
};

export default HiringProcess;
