import React from "react";

import logo1 from "./../assets/images/Company Logo/Logo 1.png";
import logo2 from "./../assets/images/Company Logo/Logo 2.png";
import logo3 from "./../assets/images/Company Logo/Logo 3.png";
import logo4 from "./../assets/images/Company Logo/Logo 4.png";
import logo5 from "./../assets/images/Company Logo/Logo 5.png";
import logo6 from "./../assets/images/Company Logo/Logo 6.png";
import logo7 from "./../assets/images/Company Logo/Logo 7.png";
import logo8 from "./../assets/images/Company Logo/Logo 8.png";
import logo9 from "./../assets/images/Company Logo/Logo 9.png";
import logo10 from "./../assets/images/Company Logo/Logo 10.png";
import logo11 from "./../assets/images/Company Logo/Logo11.png";
import logo12 from "./../assets/images/Company Logo/Logo12.png";
const Brand = () => {
  return (
    <>
      <div className="unselectable">
        <div className="col">
          <h4
            className="headings-thankyou mt-2"
            style={{ textAlign: "center" }}
          >
            Trusted By
          </h4>
          <div className="col-md-8 m-auto">
            <div className="row text-center">
              <div className="col-sm">
                <img
                  src={logo1}
                  style={{ pointerEvents: "none" }}
                  style={{ width: "100px", height: "100px" }}
                ></img>
              </div>
              <div className="col-sm">
                <img
                  src={logo2}
                  style={{ pointerEvents: "none" }}
                  style={{ width: "100px", height: "100px" }}
                ></img>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-sm">
                <img
                  src={logo3}
                  style={{ pointerEvents: "none" }}
                  style={{ width: "100px", height: "100px" }}
                ></img>
              </div>

              <div className="col-sm">
                <img
                  src={logo4}
                  style={{ pointerEvents: "none" }}
                  style={{ width: "100px", height: "100px" }}
                ></img>
              </div>
            </div>

            <div className="row text-center">
              <div className="col-sm">
                <img
                  src={logo5}
                  style={{ pointerEvents: "none" }}
                  style={{ width: "100px", height: "100px" }}
                ></img>
              </div>

              <div className="col-sm">
                <img
                  src={logo6}
                  style={{ pointerEvents: "none" }}
                  style={{ width: "100px", height: "100px" }}
                ></img>
              </div>
            </div>

            <div className="row text-center">
              <div className="col-sm">
                <img
                  src={logo7}
                  style={{ pointerEvents: "none" }}
                  style={{ width: "100px", height: "100px" }}
                ></img>
              </div>

              <div className="col-sm">
                <img
                  src={logo8}
                  style={{ pointerEvents: "none" }}
                  style={{ width: "100px", height: "100px" }}
                ></img>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-sm">
                <img
                  src={logo9}
                  style={{ pointerEvents: "none" }}
                  style={{ width: "100px", height: "100px" }}
                ></img>
              </div>
              <div className="col-sm">
                <img
                  src={logo11}
                  style={{ pointerEvents: "none" }}
                  style={{ width: "120px", height: "100px" }}
                ></img>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-sm">
                <img
                  src={logo12}
                  style={{ pointerEvents: "none" }}
                  style={{ width: "120px", height: "100px" }}
                ></img>
              </div>
            </div>
          </div>
          <br />

          <br />
        </div>
      </div>
      <div className="mobile3">
        <center></center>
      </div>
    </>
  );
};

export default Brand;
