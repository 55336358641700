import React from "react";
import { BsStar, BsStarFill } from "react-icons/bs";
import Testimonial from "./assets/images/Testimonials.png";
import Carousel from "react-multi-carousel";
import { BsCircleFill } from "react-icons/bs";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    paritialVisibilityGutter: 100,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

const Testimonials = () => {
  const CustomDot = ({ onMove, index, onClick, active }) => {
    return (
      <li style={{ fontSize: "12px" }} onClick={() => onClick()}>
        <BsCircleFill color={active ? "#0765f6" : "lightgray"} />
        &nbsp; &nbsp;
      </li>
    );
  };

  return (
    <div className="unselectable">
      <div className="row">
        <div className="col-md-4">
          <h1 className="headings">Success Stories</h1>
          <p className="para">
            Our clients help and strengthen our persistence to deliver
            industry-focused technology solutions every day by their positive
            affirmations.
          </p>
          <div className="col-md-10">
            <img
              src={Testimonial}
              alt=""
              style={{ height: "100%", width: "100%", pointerEvents: "none" }}
            />
          </div>
        </div>

        <div className="col-md-8">
          <div
            className="row"
            style={{
              paddingBottom: "30px",
              position: "relative",
            }}
          >
            <Carousel
              itemClass="image-item"
              responsive={responsive}
              arrows={false}
              showDots={true}
              customDot={<CustomDot />}
              autoPlay
              autoPlaySpeed={5000}
              infinite
              renderDotsOutside={true}
            >
              <div className="testimonial-card">
                <div
                  className="mt-3 mb-3"
                  style={{
                    fontSize: "2rem",
                    color: "#0765f6",
                  }}
                >
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStar></BsStar>&nbsp;
                  <BsStar></BsStar>
                </div>
                <p>
                  Nimap is a collective team of IT developers who are well
                  trained and certified for coding all kinds of codes. Their
                  amazing team members can code and design any app as per your
                  requirements.{" "}
                </p>
                <div className="testimonial-card-footer">
                  <h6>Krish Iyer</h6>
                  <p>Walmart-Executive Director </p>
                </div>
              </div>
              <div className="testimonial-card">
                <div
                  className="mt-3 mb-3"
                  style={{ fontSize: "2rem", color: "#0765f6" }}
                >
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStar></BsStar>
                </div>
                <p>
                  Nimap provides you extremely productive and helpful services
                  to understand and increase your business. They offer you loads
                  of choice to design your app into various different techniques
                  and ways. It's been great working with their team members.
                </p>
                <div className="testimonial-card-footer">
                  <h6>Calvin Lee </h6>
                  <p>Skyscape - Group Head</p>
                </div>
              </div>

              <div className="testimonial-card">
                <div
                  className="mt-3 mb-3"
                  style={{ fontSize: "2rem", color: "#0765f6" }}
                >
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStar></BsStar>
                </div>
                <p>
                  Using their outsourcing service they offer you a long list of
                  professionals that are eager to work with you. I hired a
                  developer from Nimap only once but my experience was so good
                  that if in future I have any work I will definitely approach
                  them.
                </p>
                <div className="testimonial-card-footer">
                  <h6>Bharat Doshi </h6>
                  <p>Ambuja cement - Marketing Manager</p>
                </div>
              </div>
              <div className="testimonial-card">
                <div
                  className="mt-3 mb-3"
                  style={{ fontSize: "2rem", color: "#0765f6" }}
                >
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStar></BsStar>&nbsp;
                  <BsStar></BsStar>
                </div>
                <p>
                  I hired a UI/UX developer to code and design for my business
                  website. Earlier I was very doubtful whether they will be able
                  to understand what I require and get me the desired output or
                  not.
                </p>
                <div className="testimonial-card-footer">
                  <h6>Lakshmappa Ragha </h6>
                  <p>Terna Engineering College - Director</p>
                </div>
              </div>

              <div className="testimonial-card">
                <div
                  className="mt-3 mb-3"
                  style={{ fontSize: "2rem", color: "#0765f6" }}
                >
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStarFill></BsStarFill>&nbsp;
                  <BsStarFill></BsStarFill>
                </div>
                <p>
                  I wanted to design a new website for my business but I was
                  very doubtful to share my company’s data. Then I met Sagar and
                  he introduced me to his web developer who was equally kind and
                  gentle. They assured me about all the security norms and
                  designed my website in a very effective manner.
                </p>
                <div className="testimonial-card-footer">
                  <h6>Mr. Brahm Vasudeva </h6>
                  <p>Hawkins - Managing Director</p>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
