import React from "react";
import FooterContactForm from "./footerContactForm";
import MacBook from "./assets/images/Macbook Pro.png";
import Facebook from "./assets/images/facebook.png";
import Instagram from "./assets/images/Instagram.png";
import linkedin from "./assets/images/linkedin.png";
import twitter from "./assets/images/twitter.png";

const Footer = () => {
  return (
    <div
      className="col-md-12 footer"
      style={{ backgroundColor: "#0765f6", padding: "50px" }}
    >
      <div className="row">
        <div className="col-md-6">
          <div className="iframe-container">
            <img alt="" src={MacBook} />
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/DOMbQuiJu3E?rel=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="col-md-10 mt-3">
            <h5>Nimap Infotech</h5>
            <p className="mt-3">
              <b>Address:</b> 41, 4Th floor A-wing, Todi Industrial Estate, Sun
              Mill Compound, Lower Parel, Mumbai - 400013{" "}
            </p>
            <p className="mt-3">
              <b>Contact Us:</b> +91 9324497696
            </p>

            <p>
              <b>Website: </b>&nbsp;&nbsp;
              <a
                href="https://www.nimapinfotech.com"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "white" }}
              >
                www.nimapinfotech.com{" "}
              </a>
            </p>
          </div>
          <div className="col">
            <div className="col-md-6 mr-5">
              <a href="https://www.facebook.com/nimap.infotech.73/">
                <img
                  alt=""
                  src={Facebook}
                  style={{ height: "40px", width: "40px", marginRight: "10px" }}
                ></img>
              </a>

              <a href="https://www.instagram.com/nimapinfotech/?utm_medium=copy_link">
                <img
                  alt=""
                  src={Instagram}
                  // src="https://www.youtube.com/embed/DOMbQuiJu3E?rel=0"
                  style={{ height: "40px", width: "40px", marginRight: "10px" }}
                ></img>
              </a>

              <a href="https://www.linkedin.com/company/nimapinfotech/">
                <img
                  alt=""
                  src={linkedin}
                  style={{ height: "40px", width: "40px", marginRight: "10px" }}
                ></img>
              </a>
              <a href="https://twitter.com/NimapInfotech">
                <img
                  alt=""
                  src={twitter}
                  style={{ height: "40px", width: "40px", marginRight: "10px" }}
                ></img>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <FooterContactForm></FooterContactForm>
        </div>
      </div>
    </div>
  );
};

export default Footer;
