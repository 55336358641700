import React from "react";
import { Formik, Form } from "formik";
import ShareRequirementIllustration from "./assets/images/Share Requirement Illustration.png";
import * as Yup from "yup";
import TextInput from "./common/inputField";
import Axios from "axios";
const ContactForm = () => {
  const validationschema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    mobileNumber: Yup.number()
      .typeError("must be a number")
      .test(
        "len",
        "Must be exactly 10 digits ",
        (val) => !val || (val && val.toString().length === 10)
      )
      .required("Mobile number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email address is required"),
    companyName: Yup.string(),
    projectDuration: Yup.string(),
    projectDesc: Yup.string(),
  });

  return (
    <div
      className="card shadow-sm p-3 mb-5 bg-white text-center cr"
      style={{ borderRadius: "15px" }}
    >
      <div className="card-body">
        <h3
          className="card-title mb-4"
          style={{ fontWeight: "bold", color: "#0765f6" }}
        >
          Share Your Requirement
        </h3>

        <Formik
          initialValues={{
            name: "",
            mobileNumber: "",
            email: "",
            companyName: "",
            projectDuration: "",
            projectDesc: "",
          }}
          validationSchema={validationschema}
          onSubmit={async (data, { resetForm }) => {
            let details = {
              name: data.name,
              mobile: data.mobileNumber,
              email: data.email,
              companyName: data.companyName,
              projectDuration: data.projectDuration,
              projectDesc: data.projectDesc,
            };

            const url = "https://raaslanding.nimapinfotech.com/api/mail";

            const res = await new Axios.post(url, details);
            console.log("res", res);
            if (res.status === 200) {
              alert("mail sent successfully");
            } else {
              alert("something went wrong...try again");
            }
            resetForm({});
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form className="contact-form" onSubmit={handleSubmit}>
              <TextInput name="name" placeholder="Name"></TextInput>
              <TextInput
                type="text"
                name="mobileNumber"
                placeholder="Mobile Number"
              ></TextInput>
              <TextInput name="email" placeholder="Email"></TextInput>
              <TextInput name="companyName" placeholder="Company"></TextInput>
              <TextInput
                name="projectDuration"
                placeholder="Project Duration"
              ></TextInput>
              {/* <TextInput
                name="projectDesc"
                placeholder="Project Description"
              ></TextInput> */}

              <button type="submit" className="form-button">
                Submit
              </button>
            </Form>
          )}
        </Formik>
        <img
          src={ShareRequirementIllustration}
          alt=""
          className="about-us-img"
          style={{ pointerEvents: "none" }}
        ></img>
      </div>
    </div>
  );
};

export default ContactForm;
