import React from "react";
import hiringModelImg1 from "./assets/images/RaaS Hiring Model 1.png";
import hiringModelImg2 from "./assets/images/RaaS Hiring Model 2.png";
import hiringModelImg3 from "./assets/images/RaaS Hiring Model 3.png";
import Banner2 from "./assets/images/Banner 2.jpeg";
import RaaS from "./assets/images/RaaS Logo.png";
import Banner2Mobile from "./assets/images/mobile_responsive/Banner 2.png";

const HiringModel = () => {
  return (
    <div className="unselectable">
      <div className="col-md-7">
        <h1 className="headings-hiring mb-3">
          <img
            alt=""
            src={RaaS}
            style={{
              height: "40.32px",
              width: "120.29px",
              pointerEvents: "none",
            }}
          ></img>
          &nbsp;Hiring Model
        </h1>
        <h1 className="headings-hiring-mob mb-3">
          <img
            alt=""
            src={RaaS}
            style={{
              height: "40.32px",
              width: "120.29px",
              pointerEvents: "none",
            }}
          ></img>
          <br />
          Hiring Model
        </h1>
        <p className="para">
          Hire dedicated developer teams within 1 hour at Zero Developer
          backout, drive your projects to completion and get the satisfaction
          you desire for with our flexible processes.
        </p>
      </div>
      <div className="row mt-4">
        <div className="col-md-4 hiring-model-card">
          <div
            className="card shadow-sm p-3 mb-4 bg-white "
            style={{ borderRadius: "10px" }}
          >
            <img
              className="card-img-top "
              src={hiringModelImg1}
              alt="Card cap"
              style={{ pointerEvents: "none" }}
            />
            <div className="card-body">
              <h5 className="card-title">Dedicated Team</h5>
              <p className="card-text">
                For large Projects
                <br />
                Evolutionary Enhancements
                <br />
                Full-time dedicated set of resources
                <br />
                No Hidden Cost
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 hiring-model-card">
          <div
            className="card shadow-sm p-3 mb-4 bg-white "
            style={{ borderRadius: "10px" }}
          >
            <img
              className="card-img-top "
              src={hiringModelImg2}
              alt="Card cap"
              style={{ pointerEvents: "none" }}
            />

            <div className="card-body">
              <h5 className="card-title">Individual Resources</h5>
              <p className="card-text">
                Full-time Dedicated Member
                <br />
                For Remote team augmentation
                <br />
                Project Management handled by you
                <br />
                No Hidden cost
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 hiring-model-card">
          <div
            className="card shadow-sm p-3 mb-4 bg-white "
            style={{ borderRadius: "10px" }}
          >
            <img
              className="card-img-top "
              src={hiringModelImg3}
              alt="Card cap"
              style={{ pointerEvents: "none" }}
            />
            <div className="card-body">
              <h5 className="card-title">Project Outsource</h5>
              <p className="card-text">
                Pre-Defined Projects
                <br />
                Milestone based Payment
                <br />
                Project Management by Us
                <br />
                No Hidden Costs
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="text-center">
        <button
          className="button"
          onClick={() =>
            window.scroll({
              top: document.body.offsetHeight,
              left: 0,
              behavior: "smooth",
            })
          }
        >
          HIRE DEDICATED DEVELOPERS
        </button>
      </div> */}
      <div className="col-md-12 mt-4 mb-3">
        <div className="banner-container">
          <img
            className="banner-img"
            src={Banner2}
            alt=""
            style={{ width: "100%", height: "100%", pointerEvents: "none" }}
          />
          <button
            className="btn"
            onClick={() =>
              window.scroll({
                top: document.body.offsetHeight,
                left: 0,
                behavior: "smooth",
              })
            }
          >
            Hire Dedicated Developers
          </button>
          {/* for mobile Responsive */}
          <img
            className="mobile-banner"
            alt=""
            src={Banner2Mobile}
            style={{ width: "110%", height: "110%" }}
            onClick={() =>
              window.scroll({
                top: document.body.offsetHeight,
                left: 0,
                behavior: "smooth",
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default HiringModel;
