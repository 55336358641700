import React, { useState } from "react";
import Popup from "./PopUp";
//import logo from "./assets/images/nimap-infotech-logo.png";
import logoMobile from "./assets/images/mobile_responsive/Nimap Logo.png";
import Facebook from "./assets/images/facebook.png";
import Nimap from "./assets/images/mobile_responsive/Nimap Logo.png";
import Artboard from "./assets/images/Artboard.png";
import Brand from "./sliderComponent/Brand";
import LastContactForm from "./LastContactForm";
import SideIcon from "./assets/images/Side Icon.png";
import { useEffect } from "react";
function PopApp() {
  const [isOpen, setIsOpen] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowPopUp(true);
    }, 3000);
  }, []);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div
        style={{
          bottom: 15,
          right: 15,
          backgroundColor: "transparent",
          padding: "20px",
          position: "fixed",
          zIndex: "3",
          borderRadius: "50%",
        }}
        onClick={togglePopup}
      >
        {showPopUp && <img src={SideIcon} />}
      </div>
      {isOpen && (
        <Popup
          content={
            <>
              <div className="col-md-12">
                <div style={{ padding: "12px 40px" }}>
                  <div
                    className="col-md-4 m-auto"
                    style={{ maxWidth: "min-content" }}
                  >
                    {/* <img
                    src={Nimap}
                    className="m-auto"
                    style={{
                      // border: "2px solid yellow",
                      margin: "auto",
                      justifyContent: "center",
                    }}
                  /> */}
                  </div>
                  <div
                    className="headings-thankyou mt-2"
                    style={{ textAlign: "center" }}
                  >
                    THANK YOU FOR YOUR INTEREST!
                  </div>

                  <div
                    className="subheadings-thankyou m-3"
                    style={{ textAlign: "center" }}
                  >
                    Have a Query? Get it cleared in few minutes! Drop here your
                    details and we will get back to you ASAP!{""}
                  </div>
                  <LastContactForm></LastContactForm>
                  <div className="row mt-4">
                    <div
                      className=" whatapp text-center col-md-6 m-auto"
                      // style={{ border: "2px solid red" }}
                    >
                      SALES
                      <br />
                      <div className="sub-headings">+919324497696</div>
                    </div>
                    <div
                      className="col-md-6 text-center m-auto"
                      // style={{ border: "2px solid red" }}
                    >
                      WHATSAPP
                      <br />
                      <div className="sub-headings"> +919819312721</div>
                    </div>
                  </div>
                </div>
                <div className="desktop-view">
                  <h4
                    className="headings-thankyou mt-2"
                    style={{ textAlign: "center" }}
                  >
                    Trusted By
                  </h4>
                  <img
                    src={Artboard}
                    className=""
                    style={{
                      // border: "2px solid red",
                      // marginRight: "10%",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  />
                </div>
              </div>
              <div className="mobile-view1 col-md-4">
                <Brand></Brand>
              </div>
            </>
          }
          handleClose={togglePopup}
        />
      )}
    </div>
  );
}

export default PopApp;

// <Popup
//           content={
//             <>
//               <div className="col-md-12">
//                   <div
//                     className="col-md-4 m-auto"
//                     style={{ maxWidth: "min-content" }}
//                   >
//                     {/* <img
//                     src={Nimap}
//                     className="m-auto"
//                     style={{
//                       // border: "2px solid yellow",
//                       margin: "auto",
//                       justifyContent: "center",
//                     }}
//                   /> */}
//                   </div>
//                   <div
//                     className="headings-thankyou mt-2"
//                     style={{ textAlign: "center" }}
//                   >
//                     THANK YOU FOR YOUR INTEREST!
//                   </div>

//                   <div
//                     className="subheadings-thankyou m-3"
//                     style={{ textAlign: "center" }}
//                   >
//                     Have a Query? Get it cleared in few minutes! Drop here your
//                     details and we will get back to you ASAP!{""}
//                   </div>
//                   <div className="mt-3">
//                     <LastContactForm></LastContactForm>
//                     <div
//                       className="row mt-4"
//                       style={{ border: "2px solid red" }}
//                     >
//                       <div
//                         className=" whatapp col-md-6 m-auto"
//                         style={{ border: "2px solid red" }}
//                       >
//                         WHATAPP
//                         <br />
//                         +919920761798
//                       </div>
//                       <div
//                         className="col-md-6 m-auto"
//                         style={{ border: "2px solid red" }}
//                       >
//                         WHATAPP
//                         <br />
//                         +919920761798
//                       </div>
//                   </div>
//                   <div className="desktop-view">
//                     <h4
//                       className="headings-thankyou mt-5"
//                       style={{ textAlign: "center" }}
//                     >
//                       Trusted By
//                     </h4>
//                     <img
//                       src={Artboard}
//                       className=""
//                       style={{
//                         border: "2px solid red",
//                         // marginRight: "10%",
//                         width: "100%",
//                         justifyContent: "center",
//                       }}
//                     />
//                   </div>
//                 </div>
//               </div>
//               <div className="mobile-view1 col-md-4">
//                 <Brand></Brand>
//               </div>
//             </>
//           }
//           handleClose={togglePopup}
//         />
