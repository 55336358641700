import React, { useEffect, useState } from "react";
import FrontEndTech from "./assets/images/Image 1.jpeg";
import BackEndTech from "./assets/images/Image 2.jpeg";
import MobileTech from "./assets/images/Image 3.jpeg";
import Database from "./assets/images/Image 4.jpeg";
import Others from "./assets/images/Image 5.jpeg";

const Technologies = () => {
  const [currentTech, setCurrentTech] = useState("frontend");
  const [techImage, setTechImage] = useState();

  useEffect(() => {
    switch (currentTech) {
      case "frontend":
        return setTechImage(FrontEndTech);
      case "backend":
        return setTechImage(BackEndTech);
      case "mobile":
        return setTechImage(MobileTech);
      case "database":
        return setTechImage(Database);
      case "others":
        return setTechImage(Others);
      default:
        return null;
    }
  }, [currentTech]);

  return (
    <div className="unselectable">
      <div className="col-md-8 text-center" style={{ margin: "auto" }}>
        <h1 className="headings mb-3">Best Technologies We Provide</h1>
        <p
          className="para"
          style={{
            textAlignLast: "center",
          }}
        >
          We provide Just-In-Time Supply of MEAN & MERN stack developers within
          the next 1 hour at extremely competitive costs without the developer
          backing out after selection by using "RaaS On-Demand IT Resource
          Staffing System" by Nimap Infotech LLP.
        </p>
      </div>
      <div className="row mt-4">
        <div className="col-md-4 tech-list ">
          <ul>
            <li>
              <span
                className={currentTech === "frontend" ? "selected" : ""}
                value="frontend"
                onClick={(e) => setCurrentTech(e.target.getAttribute("value"))}
              >
                FRONT END FRAMEWORK
              </span>
            </li>
            <li className="mt-4">
              <span
                className={currentTech === "backend" ? "selected" : ""}
                value="backend"
                onClick={(e) => setCurrentTech(e.target.getAttribute("value"))}
              >
                BACKEND TECHNOLOGIES
              </span>
            </li>
            <li className="mt-4">
              <span
                className={currentTech === "mobile" ? "selected" : ""}
                value="mobile"
                onClick={(e) => setCurrentTech(e.target.getAttribute("value"))}
              >
                MOBILE TECHNOLOGIES
              </span>
            </li>
            <li className="mt-4">
              <span
                value="database"
                className={currentTech === "database" ? "selected" : ""}
                onClick={(e) => setCurrentTech(e.target.getAttribute("value"))}
              >
                DATABASE
              </span>
            </li>
            <li className="mt-4">
              <span
                value="others"
                className={currentTech === "others" ? "selected" : ""}
                onClick={(e) => setCurrentTech(e.target.getAttribute("value"))}
              >
                OTHERS
              </span>
            </li>
          </ul>
        </div>
        <div className="col-md-8">
          <img
            src={techImage}
            alt=""
            style={{ width: "105%", height: "105%", pointerEvents: "none" }}
          ></img>
        </div>
      </div>
    </div>
  );
};

export default Technologies;
