import React, { useState } from "react";
import logo from "./assets/images/nimap-infotech-logo.png";
import { BsX } from "react-icons/bs";
import logoMobile from "./assets/images/mobile_responsive/Nimap Logo.png";

const Navbar = (props) => {
  const [navItems, setNavItems] = useState(false);

  window.addEventListener("resize", () => setNavItems(false));

  return (
    <nav className="col-md-12 navbar navbar-expand-lg navbar-light unselectable">
      <img
        className="logo"
        style={{ pointerEvents: "none" }}
        src={logo}
        alt=""
      ></img>
      <img
        className="logo-mobile"
        style={{ pointerEvents: "none" }}
        src={logoMobile}
        alt=""
      ></img>

      <button
        className="navbar-toggler m-3 bg-white"
        type="button"
        data-toggle="collapse"
        onClick={() => setNavItems(true)}
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id={navItems ? "hidden" : ""}>
        <ul className="navbar-nav " style={{ width: "100%" }}>
          <li className="nav-item navbar-item" hidden={!navItems}>
            <img
              className="logo-mobile2"
              src={logoMobile}
              alt=""
              style={{
                marginLeft: "10px",
              }}
            ></img>
            <BsX
              style={{
                fontWeight: "bold",
                float: "right",
                fontSize: "2.5rem",
                color: "dimgray",
              }}
              onClick={() => setNavItems(false)}
            />
          </li>
          <hr />

          <li className="nav-item navbar-item ml-5">
            <span
              className="nav-link"
              onClick={() => {
                props.scrollitem("hiring");
                setNavItems(false);
              }}
            >
              Hiring Process
            </span>
          </li>
          <li className="nav-item navbar-item">
            <span
              className="nav-link"
              onClick={() => {
                props.scrollitem("tech");
                setNavItems(false);
              }}
            >
              Our Technologies
            </span>
          </li>
          <li className="nav-item navbar-item">
            <span
              className="nav-link"
              onClick={() => {
                props.scrollitem("aboutus");
                setNavItems(false);
              }}
            >
              About Us
            </span>
          </li>
          <li className="nav-item navbar-item">
            <span
              className="nav-link"
              onClick={() => {
                props.scrollitem("testimonials");
                setNavItems(false);
              }}
            >
              Testimonials
            </span>
          </li>
          <li className="nav-item navbar-item">
            <span
              className="nav-link"
              onClick={() => {
                props.scrollitem("contactus");
                setNavItems(false);
              }}
            >
              Contact Us
            </span>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
