import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextInput from "./common/inputField";
import Axios from "axios";

const FooterContactForm = () => {
  const validationschema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    mobileNumber: Yup.number()
      .typeError("must be a number")
      .test(
        "len",
        "Must be exactly 10 digits ",
        (val) => !val || (val && val.toString().length === 10)
      )
      .required("Mobile number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email address is required"),
    companyName: Yup.string(),
    projectDuration: Yup.string(),
    projectDesc: Yup.string(),
  });

  return (
    <Formik
      initialValues={{
        name: "",
        mobileNumber: "",
        email: "",
        companyName: "",
        projectDuration: "",
        projectDesc: "",
      }}
      validationSchema={validationschema}
      onSubmit={async (data, { resetForm }) => {
        let details = {
          name: data.name,
          mobile: data.mobileNumber,
          email: data.email,
          companyName: data.companyName,
          projectDuration: data.projectDuration,
          projectDesc: data.projectDesc,
        };

        const url = "https://raaslanding.nimapinfotech.com/api/mail";
        console.log("url", url);
        const res = await new Axios.post(url, details);
        console.log("res", res);
        if (res.status === 200) {
          alert("mail sent successfully");
        } else {
          alert("something went wrong...try again");
        }
        resetForm({});
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="row mt-3">
            <div className="col-md-4" style={{ color: "white" }}>
              Name
            </div>
            <div className="col-md-8">
              <TextInput name="name" placeholder="Enter your Name"></TextInput>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4" style={{ color: "white" }}>
              Mobile
            </div>
            <div className="col-md-8">
              <TextInput
                type="text"
                name="mobileNumber"
                placeholder="Enter your Mobile Number"
              ></TextInput>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4" style={{ color: "white" }}>
              Email{" "}
            </div>
            <div className="col-md-8">
              <TextInput
                name="email"
                placeholder="Enter your Email ID"
              ></TextInput>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4" style={{ color: "white" }}>
              Company Name{" "}
            </div>
            <div className="col-md-8">
              <TextInput
                name="companyName"
                placeholder="Enter your Company Name"
              ></TextInput>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4" style={{ color: "white" }}>
              Project Duration
            </div>
            <div className="col-md-8">
              <TextInput
                name="projectDuration"
                placeholder="Enter your Project Duration"
              ></TextInput>
            </div>
          </div>
          <div className="row mt-3">
            {/* <div className="col-md-4" style={{ color: "white" }}>
              Description{" "}
            </div> */}
            <div className="col-md-8">
              {/* <TextInput
                name="projectDesc"
                placeholder="Enter your Project Description"
              ></TextInput> */}
            </div>
          </div>

          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-8 text-center mt-3">
              <button type="submit" className="footer-form-button">
                SUBMIT
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FooterContactForm;
