import React from "react";

const PopUp = (props) => {
  return (
    <div className="popup-box" style={{ zIndex: "1" }}>
      <div className="box mt-2">
        {/* <span className="float-end " onClick={props.handleClose}>
          x
        </span> */}
        {/* <button
          type="button"
          className="float-end"
          aria-label="Close"
          onClick={props.handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button> */}
        <button
          type="button"
          className="btn-close float-end"
          onClick={props.handleClose}
          style={{
            marginRight: "10px",
            marginTop: "8px",
          }}
        ></button>
        {props.content}
      </div>
    </div>
  );
};

export default PopUp;
